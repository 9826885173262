



























































































































































import { API_SETTING_WEIXIN_API } from "@/configs/Apis";
import { MSG_INVALID_PAGE, MSG_MODIFY_SUCCESS } from "@/configs/Consts";
import { hasPermission, showError, showSuccess, showWarning } from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import { Component, Vue } from "vue-property-decorator";
@Component({
  filters: {
    passIt: function (str: string) {
      if (!str) {
        return "";
      }
      const len = str.length;
      if (len > 6) {
        return str.substring(0, 3) + "*".repeat(len - 6) + str.substring(len - 3);
      }
      return str;
    }
  }
})
export default class WeixinApiSetting extends Vue {
  isEditing = false;
  settingItem: any = {};
  created() {
    this.loadData();
  }

  hasPerm(checkedPerm: string) {
    return hasPermission(this.$store.getters["user/user"] || {}, checkedPerm);
  }

  loadData() {
    Ajax.queryData(API_SETTING_WEIXIN_API, {}, "GET")
      .then(data => {
        this.settingItem = JSON.parse(JSON.stringify(data || {}));
      })
      .catch(() => "");
  }

  editInfo() {
    this.isEditing = true;
  }

  cancelEdit() {
    this.isEditing = false;
    this.loadData();
  }

  submitData() {
    const form = this.$refs.settingForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      Ajax.saveData(
        API_SETTING_WEIXIN_API,
        JSON.parse(JSON.stringify(this.settingItem)),
        "PUT"
      )
        .then(() => {
          showSuccess(MSG_MODIFY_SUCCESS);
        })
        .catch(({ message }) => showError(message));
    });
  }
}
